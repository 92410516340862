import { SEO } from 'components/SEO';
import React from 'react';
import {
  useProductBySlug,
  useProductVariants,
  useThankyouData,
} from 'utils/hooks';
import { PageSectionType } from 'utils/types';

import Layout from '../components/Layout';
import {
  HeroContainer as HeroSection,
  HeroContainerProps,
} from '../containers/Enterprise/HeroContainer';
import {
  RecommendedArticlesContainer as TwoCardInfoSection,
  RecommendedArticlesContainerProps,
} from '../containers/Homepage/RecommendedArticlesContainer';
import {
  DefaultProps,
  DefaultSection,
} from '../containers/PageSections/Default';

const META = {
  DESCRIPTION: 'Thank you for your interest in a paratnership with Everlywell',
  KEYWORDS: 'Everlywell, Business, Thank You,',
  URL: 'https://www.everlywell.com/thank-you',
  TITLE: 'Thank You for your interest in Everlywell',
};
const components: {
  HeroSection: React.FunctionComponent<HeroContainerProps>;
  DefaultSection: React.FunctionComponent<DefaultProps>;
  TwoCardInfoSection: React.FunctionComponent<RecommendedArticlesContainerProps>;
} = {
  HeroSection,
  DefaultSection,
  TwoCardInfoSection,
};

const ThankYouPage: React.FC = () => {
  const productVariants = useProductVariants();
  const productsBySlug = useProductBySlug(productVariants);
  const contentfulPage = useThankyouData();
  const { sections, heroSection, faqSchema } = contentfulPage;

  if (!sections || !heroSection) {
    return null;
  }

  return (
    <>
      <SEO
        description={META.DESCRIPTION}
        keywords={META.KEYWORDS}
        url={META.URL}
        title={META.TITLE}
        faqSchema={faqSchema}
      />
      <Layout productsBySlug={productsBySlug}>
        <div data-testid="thankYouPage">
          <HeroSection content={heroSection} />
          {sections.map((section: PageSectionType, i: number) => {
            // @ts-ignore
            if (components[section.componentName]) {
              // @ts-ignore
              return React.createElement(components[section.componentName], {
                content: section,
                key: `${section.componentName}-${i}`, // handles the case for duplicate section keys
              });
            }
          })}
        </div>
      </Layout>
    </>
  );
};

export default ThankYouPage;
